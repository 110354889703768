import React, { useContext, useState, useEffect } from "react";
import { Location } from "@reach/router";
import { Swipeable } from "react-swipeable";
import { navigate } from "../../utils";
import { styled, media } from "../../styles";
import { Page, Content, TitleImage, Link, Category } from "../../components";
import { ProjectInterface } from "./ProjectInterface";
import { WorkContext } from "../WorkPage";
import { useKeyPress } from "../../hooks";
import workImg from "./assets/Illu_Work.svg";

export interface ProjectProps {
  location: Location;
  pageContext: { lang: "de" | "en" };
  ["*"]: string;
}

export const ProjectPage: React.FC<ProjectProps> = ({
  location,
  pageContext: { lang },
  ...props
}) => {
  const { state } = useContext(WorkContext);

  const [projectSlug, setProjectSlug] = useState<string | undefined>();
  const [project, setProject] = useState<ProjectInterface | undefined>();
  const [prev, setPrev] = useState<string | undefined>();
  const [next, setNext] = useState<string | undefined>();

  const pathname =
    typeof window !== "undefined" && window.location && window.location.pathname
      ? window.location.pathname
      : "";

  useEffect(() => {
    if (state.initialized) {
      const projectSlugFromPath: string =
        pathname
          .split("/")
          .filter(
            part =>
              part.trim() !== "" &&
              part.trim() !== "en" &&
              part.trim() !== "work" &&
              part.trim() !== "project"
          )[0] || "";

      if (projectSlug !== projectSlugFromPath) {
        setProjectSlug(projectSlugFromPath);
      }

      let selectedProjectIdx = state.filteredProjects.findIndex(
        project => project.slug === projectSlugFromPath
      );

      if (selectedProjectIdx < 0) {
        selectedProjectIdx = -1;
      }

      setProject(state.filteredProjects[selectedProjectIdx]);

      setPrev(
        selectedProjectIdx >= 0
          ? state.filteredProjects[
              selectedProjectIdx - 1 == -1
                ? state.filteredProjects.length - 1
                : selectedProjectIdx - 1
            ].slug
          : undefined
      );

      setNext(
        selectedProjectIdx >= 0
          ? state.filteredProjects[
              selectedProjectIdx + 1 >= state.filteredProjects.length
                ? 0
                : selectedProjectIdx + 1
            ].slug
          : undefined
      );
    }
  }, [pathname, state.initialized]);

  const goToCategory = () => {
    navigate(`/work/${state.selectedCategory}/}`);
  };

  const goToPrev = () => {
    if (prev) {
      navigate(`/work/project/${prev}/`);
    }
  };

  const goToNext = () => {
    if (next) {
      navigate(`/work/project/${next}/`);
    }
  };

  useKeyPress(27, goToCategory, [state.selectedCategory]);

  useKeyPress(37, goToPrev, [prev]);

  useKeyPress(39, goToNext, [next]);

  if (!project) {
    // navigate(`/work/${state.selectedCategory || ""}`);

    return null;
  }

  const swipeConfig = {
    delta: 25
  };

  const onSwipedLeft = () => {
    if (prev) {
      navigate(`/work/project/${prev}/`);
    }
  };

  const onSwipedRight = () => {
    if (next) {
      navigate(`/work/project/${next}/`);
    }
  };

  return (
    <Swipeable
      onSwipedLeft={onSwipedLeft}
      onSwipedRight={onSwipedRight}
      {...swipeConfig}
    >
      <ProjectPageContainer
        title="Work"
        seoTitle={project.title}
        footer={false}
        isModal={true}
        backLink={`/work/${state.selectedCategory}`}
        sideLabel={false}
        mobileHeaderFixed={false}
        key={project.title}
        {...props}
      >
        {state.initialized && project ? (
          <Content>
            <ProjectHeaderContainer>
              <ProjectTitleImage title={project.title} src={workImg} />
              <ProjectHeading1>{project.title}</ProjectHeading1>
            </ProjectHeaderContainer>

            <ProjectContainer>
              <ProjectVideoContainer>
                <ProjectHeading3>{project.title}</ProjectHeading3>

                <ProjectVideo heightRatio={project.heightRatio}>
                  <iframe
                    key={project.videoId}
                    src={`//www.youtube.com/embed/${project.videoId}?showinfo=0&rel=0&controls=1&iv_load_policy=3&modestbranding=1`}
                    frameBorder="0"
                    allowFullScreen={true}
                  />
                </ProjectVideo>
              </ProjectVideoContainer>

              <ProjectContentContainer>
                <ProjectHeading2>{project.title}</ProjectHeading2>

                <ProjectTextContainer>
                  <ProjectFacts>
                    {[
                      ...project.facts.slice(0, 4),
                      ...(project.facts.length > 4
                        ? project.facts.slice(-1)
                        : [])
                    ].map((fact, idx) => (
                      <ProjectFact key={fact.name + idx}>
                        {fact.type}
                        <span>{fact.name}</span>
                      </ProjectFact>
                    ))}
                  </ProjectFacts>

                  <ProjectDescription>
                    {project.description[lang]}
                  </ProjectDescription>
                </ProjectTextContainer>

                <ProjectCategories>
                  {project.categories.map(category => {
                    if (category.slug === "recent") {
                      return null;
                    }

                    return (
                      <ProjectCategory
                        key={category.slug}
                        to={`/work/${category.slug}/`}
                      >
                        {category.name}
                      </ProjectCategory>
                    );
                  })}
                </ProjectCategories>
              </ProjectContentContainer>
            </ProjectContainer>

            {prev && (
              <PrevLink
                to={`/work/project/${prev}/`}
                state={{
                  ...location.state
                }}
              >
                {"≤"}
              </PrevLink>
            )}

            {next && (
              <NextLink
                to={`/work/project/${next}/`}
                state={{
                  ...location.state
                }}
              >
                {"≥"}
              </NextLink>
            )}
          </Content>
        ) : null}
      </ProjectPageContainer>
    </Swipeable>
  );
};

const ProjectPageContainer = styled(Page)`
  min-height: 100vh;
`;

const ProjectTitleImage = styled(TitleImage)`
  ${media.landscape} {
    width: 50%;

    img {
      width: 100%;
    }
  }

  ${media.tablet} {
    width: 50%;

    img {
      width: 100%;
    }
  }

  ${media.laptop} {
    flex: 0 0 45%;
    margin-top: 1%;

    img {
      width: 100%;
    }
  }
`;

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.laptop} {
    flex-direction: row-reverse;

    align-items: flex-start;
    align-content: flex-start;
  }
`;

const ProjectHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ProjectContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${media.laptop} {
    flex: 40%;
  }
`;

const ProjectTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
  margin-right: 5%;

  ${media.tablet} {
    flex-direction: row-reverse;
    margin-top: 0;
    margin-right: 0;
  }

  ${media.laptop} {
    flex-direction: column-reverse;
    margin-top: 5%;
    margin-left: 20%;
  }
`;

const ProjectHeading1 = styled.h2`
  display: none;

  ${media.laptop} {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex: 1 1 20%;
    margin: 0 5% 2.5rem 1rem;
    font-size: 4rem;
    line-height: 0.9;
    overflow: visible;
  }
`;

const ProjectHeading2 = styled.h2`
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.1;

  ${media.tablet} {
    display: none;
  }
`;

const ProjectHeading3 = styled.h2`
  display: none;

  ${media.tablet} {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  ${media.laptop} {
    display: none;
  }
`;

const ProjectVideoContainer = styled.div`
  align-self: flex-start;
  display: block;
  flex: 1;
  margin: 10px -40px 0 -40px;

  width: 100vw;

  ${media.landscape} {
    margin: 0;
    width: 100%;
  }

  ${media.tablet} {
    margin: 0 0 5% 0;
    width: 100%;
  }

  ${media.laptop} {
    flex: 0 0 60%;
  }
`;

const ProjectVideo = styled.div<{ heightRatio: number }>`
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: ${({ heightRatio }) => heightRatio}%;
  max-width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ProjectFacts = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  ${media.tablet} {
    flex-basis: 40%;
    margin-right: 5%;
  }

  ${media.laptop} {
    flex-basis: auto;
  }
`;

const ProjectFact = styled.div`
  font-size: 14px;
  margin-bottom: 0.2rem;

  ${media.tablet} {
    text-align: right;
  }

  ${media.laptop} {
    text-align: left;
  }

  ${media.desktop} {
    font-size: 16px;
  }

  span {
    font-weight: bold;
    margin-left: 0.5rem;
  }
`;

const ProjectCategories = styled.div`
  display: none;

  ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
    margin: 5% 0 5% 5%;
  }

  ${media.laptop} {
    margin: 0 0 0 20%;
  }
`;

const ProjectCategory = styled(Category)`
  font-size: 16px;
  margin: 0 0.5rem 0.5rem 0;

  ${media.desktop} {
    font-size: 18px;
  }
`;

const ProjectDescription = styled.p`
  flex: 1;

  ${media.tablet} {
    margin: 0 0 0 5%;
  }

  ${media.laptop} {
    flex: 0;
    margin: 0 10% 10% 0;
    font-size: 20px;
  }

  ${media.desktop} {
    margin: 10% 10% 5% 0;
  }
`;

const PrevLink = styled(Link)`
  display: none;

  ${media.tablet} {
    display: block;
    position: fixed;
    left: 0;
    top: 25vh;
    height: 50vh;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }
`;

const NextLink = styled(Link)`
  display: none;

  ${media.tablet} {
    display: block;
    position: fixed;
    right: 0;
    top: 25%;
    height: 50%;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }
`;
